import React from 'react';
import './App.css';
import Header from './components/Header'; // Import the Header component
import Footer from './components/Footer'; // Import the Footer component

function App() {
  return (
    <div 
      className="App" 
      style={{ 
        minHeight: '100vh', // Full height for viewport
        display: 'flex', // Flex container
        flexDirection: 'column', // Stack children vertically
      }}
    >
      <Header /> 
      <div style={{ flexGrow: 1 }}></div> {/* Spacer to push footer to bottom */}
      <Footer />
    </div>
  );
}

export default App;
