import { Box, useMediaQuery } from '@mui/material';
import image1 from '../assets/sketchbook-1.webp';
import image5 from '../assets/sketchbook-2s.webp';
import image6 from '../assets/lasamericas-sketch.webp';
import image3 from '../assets/Coggin-Sketchbook-Manchester-Landmarks-Cushion.webp';
import image2 from '../assets/localsemnome.webp';
import image4 from '../assets/sagrada-sketch.webp';

const Collage = () => {
  const images = [
    { src: image1, width: 4272, height: 2848 },
    { src: image2, width: 1819, height: 1311 },
    { src: image3, width: 768, height: 512 },
    { src: image4, width: 1816, height: 1308 },
    { src: image5, width: 4272, height: 2848 },
    { src: image6, width: 4272, height: 2844 },
  ];
  const isMobile = useMediaQuery('(max-width:600px)'); // Check if screen is small

  // Filter images for mobile view
  const filteredImages = isMobile ? [images[0], images[2], images[3]] : images;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)', // Two images per row on mobile
          md: 'repeat(3, 1fr)', // Three images per row on desktop
        },
        gap: '10px', // Space between images
        maxWidth: '1000px', // Max container width
        margin: '40px auto', // Center the collage and add padding
        padding: '20px', // Padding around the gallery
      }}
    >
      {filteredImages.map((image, index) => (
        <Box
          key={index}
          sx={{
            position: 'relative',
            width: '100%', // Full width of grid cell
            height: 0, // Trick to maintain aspect ratio
            paddingBottom: `${(image.height / image.width) * 100}%`,
            overflow: 'hidden',
            backgroundColor: '#f0f0f0', // Optional: Placeholder color
          }}
        >
          <Box
            component="img"
            src={image.src}
            alt={`Collage image ${index + 1}`}
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              objectFit: 'cover', // Adjust to fit the grid cell
              transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'scale(1.05)', // Slight zoom on hover
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)', // Subtle shadow on hover
              },
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default Collage;
