
import { Box, Typography, Avatar, Button, Link } from '@mui/material';
import Collage from './Collage'; 
import TeatroGallery from './TeatroGallery'; 
import profileImage from '../assets/sara-headshot-sm.webp';
import laptopImage from '../assets/ai-writing.webp';
import languageGraph from '../assets/language-graph.webp'

const Header = () => {
  
 
  
  

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        padding: { xs: '30px 20px', md: '60px 20px' }, 
        backgroundColor: '#ffffff',
        color: '#333333',
      }}
    >
      {/* Main Title and Image */}
      <Typography 
        variant="h2" 
        component="h1" 
        gutterBottom 
        sx={{ 
          fontSize: { xs: '3.5rem', md: '5rem' }, 
          fontFamily: `'Courier Prime', monospace`, 
          fontWeight: 'normal',
        }}
      >
        Hi, I'm Sara
      </Typography>
      <Avatar
        alt="Sara's Headshot"
        src={profileImage}
        sx={{
          width: { xs: 200, md: 300}, // Adjust size for smaller and larger screens
          height: { xs: 200, md: 300 }, // Circular avatar (height = width)
          margin: '20px 0', // Add consistent vertical spacing
          
        }}
      />
      {/* Short Bio */}
      <Typography 
        variant="h6" 
        component="p" 
        sx={{ 
          maxWidth: '600px', 
          fontSize: { xs: '1rem', md: '1.1rem' },
          fontFamily: `'Courier Prime', monospace`, 
          color: '#333333',
          marginTop: { xs: '20px', md: '30px' }, 
          marginBottom: { xs: '20px', md: '40px' },
          textAlign: 'center',
        }}
      >
        I'm an artist, writer, and content marketer who loves going on adventures and telling stories.
      </Typography>

      {/* Call-to-Action Buttons */}
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: '15px', 
          alignItems: 'center',
          marginBottom: '40px',
        }}
      >
        <Button 
          variant="outlined" 
          href="https://saracoggin.substack.com"
          sx={{ 
            width: { xs: '100%', sm: '500px', md: '550px' }, 
            padding: { xs: '15px 25px', md: '20px 30px' },
            fontSize: { xs: '1.2rem', md: '1.5rem' },
            textTransform: 'none', 
            fontWeight: 'normal',
            borderColor: '#333333', 
            color: '#333333', 
            '&:hover': { 
              borderColor: '#FF1493', 
              color: '#555555', 
              backgroundColor: 'transparent' 
            },
            fontFamily: 'inherit',
          }}
         >
          Join my newsletter
        </Button>
        
        <Button 
          variant="outlined"
          href="https://www.linkedin.com/in/coggin-sara/" 
          sx={{ 
            width: { xs: '100%', sm: '500px', md: '550px' },
            padding: { xs: '15px 25px', md: '20px 30px' },
            fontSize: { xs: '1.2rem', md: '1.5rem' },
            textTransform: 'none',
            fontWeight: 'normal',
            borderColor: '#333333',
            color: '#333333',
            '&:hover': { 
              borderColor: '#FF1493',
              color: '#555555',
              backgroundColor: 'transparent'
            },
            fontFamily: 'inherit',
          }}
        >
          Connect on LinkedIn
        </Button>
      </Box>

      {/* What I'm Working On Section */}
      <Box sx={{ width: '100%', maxWidth: '800px', marginTop: '60px', paddingX: '20px' }}>
        <Typography variant="h4" sx={{ fontFamily: `'Courier Prime', monospace`, fontWeight: 'normal', borderBottom: '2px solid #333', paddingBottom: '10px', marginBottom: '30px', textAlign: 'center' }}>
          WHAT I'M WORKING ON
        </Typography>

        {/* Content Marketing Section */}
        <Typography variant="h5" sx={{ fontFamily: `'Courier Prime', monospace`, fontWeight: 'bold', marginTop: '40px' }}>
          Content Marketing @ <Link href="https://www.animalz.co" target="_blank" rel="noopener noreferrer" sx={{ color: 'inherit', textDecoration: 'underline', '&:hover': { color: '#FF1493', textDecoration: 'underline' } }}>Animalz</Link>
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: `'Courier Prime', monospace`, marginTop: '10px', textAlign: 'left' }}>
          Associate Head of Content. I help companies to grow through high-quality, original content. Content strategy. Project management. Writing. Editing. 
        </Typography>
        <Box sx={{ borderBottom: '1px solid #ddd', marginY: '20px' }} />

        {/* Experimenting Section */}
        <Typography variant="h5" sx={{ fontFamily: `'Courier Prime', monospace`, fontWeight: 'bold', marginTop: '40px' }}>
          Experimenting
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: `'Courier Prime', monospace`, marginTop: '10px', textAlign: 'left' }}>
          I’m passionate about using emerging technologies to create better work, faster. My brother and I started <Link href="https://saracoggin.substack.com/p/behind-the-scenes-of-the-travel-diary" target="_blank" rel="noopener noreferrer" sx={{ color: 'inherit', textDecoration: 'underline', '&:hover': { color: '#FF1493', textDecoration: 'underline' } }}>experimenting with generative AI</Link> in 2020. We fine-tuned GPT-2 to write travel blog posts. Have a read:
        </Typography>
        <Box component="ul" sx={{ fontFamily: `'Courier Prime', monospace`, marginTop: '10px', paddingLeft: '20px', textAlign: 'left' }}>
          <li><Link href="https://aitraveldiary.substack.com/p/my-visit-to-rio" target="_blank" rel="noopener noreferrer" sx={{ color: 'inherit', textDecoration: 'underline', '&:hover': { color: '#FF1493', textDecoration: 'underline' } }}>Earthquakes, thunderstorms, and giant dog statues in Rio de Janeiro</Link></li>
          <li><Link href="https://aitraveldiary.substack.com/p/walking-around-seville" target="_blank" rel="noopener noreferrer" sx={{ color: 'inherit', textDecoration: 'underline', '&:hover': { color: '#FF1493', textDecoration: 'underline' } }}>Local beer, chasing dreams, and flower-lined streets in Seville</Link></li>
          <li><Link href="https://aitraveldiary.substack.com/p/reykjavik" target="_blank" rel="noopener noreferrer" sx={{ color: 'inherit', textDecoration: 'underline', '&:hover': { color: '#FF1493', textDecoration: 'underline' } }}>Glaciers, surfing, and a week-long party in Reykjavík</Link></li>
        </Box>
        
          <Box
            component="img"
            src={laptopImage}
            alt="A laptop screen with code on"
            sx={{
              width: '100%',
              maxWidth: '800px',
              margin: '40px auto',
              display: 'block',
               }}
          />
        
        <Typography variant="body1" sx={{ fontFamily: `'Courier Prime', monospace`, marginTop: '10px', textAlign: 'left' }}>
         I believe technology should help us live easier, more beautiful, and more creative lives.
        </Typography>
        <Box sx={{ borderBottom: '1px solid #ddd', marginY: '20px' }} />

        {/* Creating Section */}
        <Typography variant="h5" sx={{ fontFamily: `'Courier Prime', monospace`, fontWeight: 'bold', marginTop: '40px' }}>
          Creating
        </Typography>
        
        <Typography variant="body1" sx={{ fontFamily: `'Courier Prime', monospace`, marginTop: '10px', textAlign: 'left' }}>
          I initially specialised in textiles, creating items for the home with illustrations of Manchester. Now I’m focused on making visuals to accompany my writing. 
        </Typography>
        <Collage />
        
        <Box sx={{ borderBottom: '1px solid #ddd', marginY: '20px' }} />

        {/* Learning Section */}
        <Typography variant="h5" sx={{ fontFamily: `'Courier Prime', monospace`, fontWeight: 'bold', marginTop: '40px' }}>
          Learning
        </Typography>
        

        {/* Technical Skills Subsection */}
        <Typography variant="h6" sx={{ fontFamily: `'Courier Prime', monospace`, fontWeight: 'bold', marginTop: '20px' }}>
          Technical Skills
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: `'Courier Prime', monospace`, marginTop: '10px', textAlign: 'left' }}>
          I continue to dabble in web development. (Related: if you find anything broken on this website, please let me know!) I’m currently working on beefing up my technical SEO chops and learning Python. 
        </Typography>

        {/* Acting Subsection */}
        <Typography variant="h6" sx={{ fontFamily: `'Courier Prime', monospace`, fontWeight: 'bold', marginTop: '20px' }}>
          Acting
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: `'Courier Prime', monospace`, marginTop: '10px', textAlign: 'left' }}>
          After studying at the Manchester School of Acting (2014-2017) I'm now continuing my training at <Link href="https://www.unratoteatro.com" target="_blank" rel="noopener noreferrer" sx={{ color: 'inherit', textDecoration: 'underline', '&:hover': { color: '#FF1493', textDecoration: 'underline' } }}>Un Rato Teatro</Link>.
        </Typography>
       <TeatroGallery />
        
        
        {/* Miscellaneous Subsection */}
        <Typography variant="h6" sx={{ fontFamily: `'Courier Prime', monospace`, fontWeight: 'bold', marginTop: '20px' }}>
          Miscellaneous
        </Typography>
        <Box component="ul" sx={{ fontFamily: `'Courier Prime', monospace`, marginTop: '10px', paddingLeft: '20px', textAlign: 'left' }}>
          <li>Salsa dancing</li>
          <li>Guitar & singing</li>
          <li>Creating the perfect yerba mate montañita</li>
        </Box>
      </Box>

      {/* FAQ Section */}
      <Box sx={{ width: '100%', maxWidth: '800px', marginTop: '60px', paddingX: '20px' }}>
        <Typography variant="h4" sx={{ fontFamily: `'Courier Prime', monospace`, borderBottom: '2px solid #333', paddingBottom: '10px', marginBottom: '30px', textAlign: 'center' }}>
          FAQs
        </Typography>

        <Typography variant="h5" sx={{ fontFamily: `'Courier Prime', monospace`, fontWeight: 'bold', marginTop: '20px' }}>
          Where do you live?
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: `'Courier Prime', monospace`, marginTop: '10px', textAlign: 'left' }}>
          I'm based in Barcelona, Spain. I work remotely with clients all over the world.
        </Typography>
        <Box sx={{ borderBottom: '1px solid #ddd', marginY: '20px' }} />

        <Typography variant="h5" sx={{ fontFamily: `'Courier Prime', monospace`, fontWeight: 'bold', marginTop: '20px' }}>
          Are you available for freelance work?
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: `'Courier Prime', monospace`, marginTop: '10px', textAlign: 'left' }}>
          Yes.
        </Typography>
        <Box sx={{ borderBottom: '1px solid #ddd', marginY: '20px' }} />

        <Typography variant="h5" sx={{ fontFamily: `'Courier Prime', monospace`, fontWeight: 'bold', marginTop: '20px' }}>
          What languages do you speak?
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: `'Courier Prime', monospace`, marginTop: '10px', textAlign: 'left' }}>
          My native language is English. I speak Spanish, French, and Portuguese with varying levels of fluency. 
        </Typography>
        <Box
          component="img"
          src={languageGraph}
          alt="Langauge graph showing levels of fluency - Spanish to Farsi"
          sx={{
            width: '100%', // Full width within container
            maxWidth: '800px', // Restrict maximum size for large screens
            margin: '40px auto', // Center with vertical spacing
            display: 'block', // Center alignment
            textAlign: 'center', // Makes sure alignment is inherited from the parent
             }}
        />
        <Box sx={{ borderBottom: '1px solid #ddd', marginY: '20px' }} />

        <Typography variant="h5" sx={{ fontFamily: `'Courier Prime', monospace`, fontWeight: 'bold', marginTop: '20px' }}>
          What's your favourite cheese?
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: `'Courier Prime', monospace`, marginTop: '10px', textAlign: 'left' }}>
           <Link href="https://en.wikipedia.org/wiki/Lancashire_cheese" target="_blank" rel="noopener noreferrer" sx={{ color: 'inherit', textDecoration: 'underline', '&:hover': { color: '#FF1493', textDecoration: 'underline' } }}>Tasty or crumbly Lancashire.</Link>
        </Typography>
      </Box>

      
    </Box>
  );
};

export default Header;
