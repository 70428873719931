import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import image1 from '../assets/sara-teatro.webp';
import image3 from '../assets/creativetype.webp';
import image2 from '../assets/sombra.webp';

const TeatroGallery = () => {
  const isMobile = useMediaQuery('(max-width:600px)'); // Detect mobile screen size

  const images = [image1, image2, image3];

  // Show only the first two images on mobile
  const displayedImages = isMobile ? images.slice(0, 2) : images;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)', // One image per row on mobile
          sm: 'repeat(2, 1fr)', // Two images per row on small screens
          md: 'repeat(3, 1fr)', // Three images per row on desktop
        },
        gap: '20px', // Space between images
        maxWidth: '1000px', // Max container width
        margin: '40px auto', // Center the gallery
        padding: '20px', // Padding around the gallery
      }}
    >
      {displayedImages.map((image, index) => (
        <Box
          key={index}
          sx={{
            position: 'relative',
            width: '100%',
            height: 0,
            paddingBottom: '150%', // Aspect ratio for portrait images
            overflow: 'hidden',
            backgroundColor: '#f0f0f0', // Placeholder color
          }}
        >
          <Box
            component="img"
            src={image}
            alt={`Teatro image ${index + 1}`}
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              objectFit: 'cover', // Ensure the image fits the grid cell
              borderRadius: '4px', // Optional rounded corners
              transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Hover effect
              '&:hover': {
                transform: 'scale(1.05)', // Slight zoom on hover
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)', // Subtle shadow
              },
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default TeatroGallery;