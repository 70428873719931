import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box 
      sx={{ 
        width: '100%', // Full width of the viewport
        textAlign: 'center', 
        paddingY: '10px', 
        backgroundColor: '#FF1493', // Hot pink background
        color: '#ffffff', // White text color
        fontFamily: `'Courier Prime', monospace`, // Typewriter font
        fontSize: '1rem',
        marginTop: 'auto', // Push to bottom
        position: 'relative', // Ensure it sits in flow
      }}
    >
      <Link 
        href="#" 
        sx={{ 
          textDecoration: 'none', 
          color: '#ffffff', 
          fontWeight: 'bold', 
          '&:hover': { color: '#dddddd' } 
        }}
      >
        Back to Top
      </Link>
      <Typography 
        variant="body2" 
        sx={{ marginTop: '5px', fontSize: '0.9rem', fontFamily: `'Courier Prime', monospace` }}
      >
        © {currentYear} Sara Coggin. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
